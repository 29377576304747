import { ChangeEvent, FC } from "react";
import useStyles from "./Checkbox.style";
import { Checkbox as MuiCheckbox } from "@material-ui/core";

const Checkbox: FC<{
  value: boolean;
  label: string;
  onChange: (checked: boolean) => void;
}> = ({ value, label, onChange }) => {
  const classes = useStyles();

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <div className={classes.Checkbox}>
      <div>{label}</div>
      <MuiCheckbox
        checked={value}
        defaultChecked
        disableRipple
        className={classes.Checkbox__checkbox}
        classes={{ checked: classes.Checkbox__checkbox__root }}
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default Checkbox;
