import { FC } from "react";
import useStyles from "./Button.style";
import { Button as MuiButton } from "@material-ui/core";
import useSound from "use-sound";
import correctSound from "../../assets/sounds/button-success-sound.mp3";
import cx from "classnames";

const Button: FC<{
  text: string;
  onClick: () => void;
  disabled?: boolean;
  sound?: string;
  cancelVariation?: boolean;
}> = ({
  text,
  onClick,
  disabled = false,
  sound = correctSound,
  cancelVariation = false,
}) => {
  const classes = useStyles();
  const [playSound] = useSound(sound);

  const clickHandler = () => {
    playSound();
    onClick();
  };

  return (
    <button
      className={cx(classes.Button, {
        [classes.Button__cancel]: cancelVariation,
      })}
      onClick={clickHandler}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Button;
