import React from "react";
import ReactDOM from "react-dom";
import ThemeProvider from "./providers/ThemeProvider";
import GlobalStylesProvider from "./providers/GlobalStylesProvider";
import MuiPickerProvider from "./providers/MuiPickerProvider";
import { Provider as ReduxProvider } from "react-redux";
import store from "./redux/store";
import App from "./app";

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ThemeProvider>
        <MuiPickerProvider>
          <GlobalStylesProvider>
            <App />
          </GlobalStylesProvider>
        </MuiPickerProvider>
      </ThemeProvider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
