import { FC } from "react";
import useStyles from "./TitleBanner.style";

const TitleBanner: FC = () => {
  const classes = useStyles();

  return <div className={classes.TitleBanner}>Word Hunt Battle</div>;
};

export default TitleBanner;
