import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  WordList: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "1rem",
  },
  WordList__left: {
    alignItems: "flex-start",
  },
  WordList__right: {
    alignItems: "flex-end",
  },
  WordList__wordContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
  },
  WordList__wordContainer__left: {
    alignItems: "flex-start",
  },
  WordList__wordContainer__right: {
    alignItems: "flex-end",
  },
  WordList__word: {
    fontSize: "3rem",
    padding: "0 1rem",
    borderRadius: "1rem",
    marginBottom: "1rem",
    backgroundColor: "#b3e2ff",
    minWidth: "5rem",
    textAlign: "center",
    lineHeight: "4rem",
  },
  WordList__userPanel: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  WordList__userPanel__left: {
    flexDirection: "row",
  },
  WordList__userPanel__right: {
    flexDirection: "row-reverse",
  },
  WordList__userPanel__icon: {
    height: "10rem",
    width: "auto",
  },
  WordList__userPanel__name: {
    fontSize: "5rem",
  },
  WordList__userPanel__ready: {
    fontSize: "5rem",
    border: "1px solid grey",
    cursor: "pointer",
    userSelect: "none",
  },
  WordList__userPanel__ready__isReady: {
    backgroundColor: "#a6ffaf",
  },
  WordList__userPanel__ready__notReady: {
    backgroundColor: "#ff9494",
  },
  WordList__score: {
    fontSize: "5rem",
  },
  WordList__animation: {
    animation: `$existingWordEffect 500ms ease-out`,
  },
  "@keyframes existingWordEffect": {
    "0%": {
      backgroundColor: "red",
      transform: "scale(1.3)",
    },
    "25%": {
      transform: "translate(-2rem, 0) scale(1.3)",
    },
    "50%": {
      transform: "translate(2rem, 0) scale(1.3)",
    },
    "75%": {
      transform: "translate(-2rem, 0) scale(1.3)",
    },
    "100%": {
      transform: "translate(0, 0) scale(1)",
      backgroundColor: "#b3e2ff",
      left: 0,
    },
  },
}));

export default useStyles;
