import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  EndGamePage: {
    display: "flex",
    justifyContent: "center",
  },
  EndGamePage__container: {
    fontSize: "4rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    marginTop: "4rem",
    minWidth: "min(45rem, 80%)",
  },
}));

export default useStyles;
