export const INITIALISE_GAME = "initialiseGame";

export const DISCONNECT_SOCKET = "disconnectSocket";

export const CHANGE_STATUS = "changeStatus";

export const INITIALISE_GAME_SOCKET = "initialiseGameSocket";

export const START_GAME = "startGame";

export const SEND_WORD = "sendWord";

export const END_GAME = "endGame";

export const SELECT_TILE = "selectTile";

export const RESET_SELECTED_TILES = "resetSelectedTiles";

export const SET_IS_WORD_VALID = "setIsWordValid";

export const SUBSCRIBE_ANIMATION = "subscribeAnimation";

export const CONSUME_ANIMATION = "consumeAnimation";

export const CHANGE_SETTING = "changeSetting";

export const SELECT_CHARACTER = "selectCharacter";

export const BACK_TO_LOBBY = "backToLobby";

export const INCREMENT_WINS = "incrementWins";

export const FAILED_TO_JOIN_ROOM = "failedToJoinRoom";
