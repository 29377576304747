import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  LobbyPage: {
    display: "flex",
    flexDirection: "column",
  },
  LobbyPage__container: {
    // width: "80rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  LobbyPage__buttons: {
    display: "flex",
    justifyContent: "space-between",
    gap: "2rem",
  },
  LobbyPage__main: {
    display: "flex",
    gap: "5rem",
  },
  LobbyPage__players: {
    display: "flex",
    gap: "5rem",
    alignItems: "center",
    justifyContent: "space-between",
  },
  LobbyPage__players__player: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50rem",
  },
  LobbyPage__players__icon: {
    width: "10rem",
  },
  LobbyPage__players__name: {
    textAlign: "center",
    fontSize: "4rem",
    marginTop: "-1.5rem",
  },
  LobbyPage__players__vs: {
    fontSize: "7rem",
  },
}));

export default useStyles;
