import { disconnectSocketAction } from "../redux/actions";
import { Character } from "../types";
import { useAppDispatch, useAppSelector } from "./redux";

const useGameSocket = () => {
  const socketRef = useAppSelector((state) => state.game.socketRef);
  const dispatch = useAppDispatch();

  const sendWord = (
    word: string,
    positions: { row: number; col: number }[]
  ) => {
    socketRef?.emit("sendWord", { word, positions });
  };

  const changeStatus = (status: string) => {
    socketRef?.emit("changeStatus", { status });
  };

  const startGame = (boardSize: number) => {
    socketRef?.emit("startGame", { boardSize });
  };

  const endGame = () => {
    socketRef?.emit("endGame");
  };

  const restartGame = () => {
    socketRef?.emit("restartGame");
  };

  const changeSetting = (settingName: string, settingValue: any) => {
    socketRef?.emit("changeSetting", { settingName, settingValue });
  };

  const selectCharacter = (character: Character) => {
    socketRef?.emit("selectCharacter", { character });
  };

  const fetchLobby = () => {
    socketRef?.emit("fetchLobby");
  };

  const backToLobby = () => {
    socketRef?.emit("backToLobby");
  };

  const incrementWins = () => {
    socketRef?.emit("incrementWins");
  };

  const disconnectGame = () => {
    socketRef?.disconnect();
  };

  return {
    sendWord,
    changeStatus,
    startGame,
    endGame,
    restartGame,
    changeSetting,
    disconnectGame,
    selectCharacter,
    fetchLobby,
    backToLobby,
    incrementWins,
  };
};

export default useGameSocket;
