import { useNavigate } from "@reach/router";
import {
  Dispatch,
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Socket } from "socket.io-client";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import Layout from "../../components/Layout/Layout";
import Modal from "../../components/Modal/Modal";
import TitleBanner from "../../components/TitleBanner/TitleBanner";
import useGameSocket from "../../hooks/useGameSocket";
import useStyles from "./LandingPage.style";

const LandingPage: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [joinRoomCode, setJoinRoomCode] = useState<string>("");
  const [joiningGame, setJoiningGame] = useState<boolean>(false);
  const { disconnectGame } = useGameSocket();

  useEffect(() => {
    disconnectGame();
  }, [location]);

  const createGameHandler = () => {
    if (name) {
      navigate(`/loading`, { state: { name, roomCode: null } });
    }
  };

  const nameChangeHandler = (value: string) => {
    setName(value);
  };

  const joinGameHandler = () => {
    if (joinRoomCode && name) {
      navigate(`/loading`, { state: { name, roomCode: joinRoomCode } });
    } else {
      setJoiningGame(false);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlRoomCode = searchParams.get("roomCode");

    if (urlRoomCode) {
      setJoinRoomCode(urlRoomCode);
      setJoiningGame(true);
    }
  }, [location.search]);

  return (
    <Layout>
      <div className={classes.LandingPage}>
        <TitleBanner />
        <div className={classes.LandingPage__menu}>
          <Input
            placeholder="What is your name"
            value={name}
            onChange={nameChangeHandler}
          />
          <Button
            text={"Start a new game"}
            onClick={createGameHandler}
            disabled={!name}
          />
          {joiningGame ? (
            <div className={classes.LandingPage__joinGameInput}>
              <div className={classes.LandingPage__joinGameInput__input}>
                <Input
                  placeholder="Enter Room Code"
                  value={joinRoomCode}
                  onChange={setJoinRoomCode}
                />
              </div>
              <div className={classes.LandingPage__joinGameInput__button}>
                <Button
                  text={"Go!"}
                  onClick={joinGameHandler}
                  disabled={!name}
                />
              </div>
            </div>
          ) : (
            <Button text={"Join a game"} onClick={() => setJoiningGame(true)} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default LandingPage;
