import { FC, Fragment } from "react";
import useStyles from "./WordList.style";
import cx from "classnames";
import Word from "../Word/Word";
import FlipMove from "react-flip-move";

const WordList: FC<{
  words?: { word: string; userId: string }[];
  position: string;
}> = ({ words = [], position }) => {
  const classes = useStyles();

  return (
    <div
      className={cx(classes.WordList, {
        [classes.WordList__left]: position === "left",
        [classes.WordList__right]: position === "right",
      })}
    >
      <FlipMove
        className={cx(classes.WordList__wordContainer, {
          [classes.WordList__wordContainer__left]: position === "left",
          [classes.WordList__wordContainer__right]: position === "right",
        })}
      >
        {[...words].reverse().map((data, index) => (
          <div key={`${data.word}-${data.userId}-${words.length - index}`}>
            <Word
              word={data.word}
              index={words.length - 1 - index}
              userId={data.userId}
            />
          </div>
        ))}
      </FlipMove>
    </div>
  );
};

export default WordList;
