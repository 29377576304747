import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Word: {
    fontSize: "2rem",
    padding: "0 1rem",
    borderRadius: "1rem",
    marginBottom: "1rem",
    backgroundColor: "#b3e2ff",
    minWidth: "5rem",
    textAlign: "center",
    lineHeight: "4rem",
    position: "relative",
  },
  Word__animation: {
    animation: `$existingWordEffect 500ms ease-out`,
  },
  "@keyframes existingWordEffect": {
    "0%": {
      backgroundColor: "red",
    },
    "25%": {
      transform: "translate(-1rem, 0)",
    },
    "50%": {
      transform: "translate(1rem, 0)",
    },
    "75%": {
      transform: "translate(-1rem, 0)",
    },
    "100%": {
      transform: "translate(0, 0) scale(1)",
      backgroundColor: "#b3e2ff",
      left: 0,
    },
  },
}));

export default useStyles;
