import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  TitleBanner: {
    fontSize: "5rem",
    display: "flex",
    justifyContent: "center",
  },
}));

export default useStyles;
