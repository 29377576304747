import { useNavigate } from "@reach/router";
import { useEffect } from "react";
import { useAppSelector } from "./redux";

export const useCleanup = () => {
  const navigate = useNavigate();
  const userId = useAppSelector((state) => state.game.userId);
  const socketRef = useAppSelector((state) => state.game.socketRef);

  useEffect(() => {
    const isSocketConnected = socketRef && socketRef.connected;

    if (!userId || !isSocketConnected) {
      navigate("/");
    }
  }, []);
};
