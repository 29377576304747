import { FC } from "react";
import useStyles from "./Status.style";
import cx from "classnames";

const Status: FC<{ status: string }> = ({ status }) => {
  const classes = useStyles();

  const statusMessage = () => {
    if (status === "ready") return "Ready";
    if (status === "idle") return "Not Ready";
    if (status === "inProgress") return "In Progress";
    if (status === "done") return "Not Ready";
  };

  return (
    <div
      className={cx(classes.Status, {
        [classes.Status__isReady]: status === "ready",
        [classes.Status__notReady]:
          status === "idle" || status === "inProgress" || status === "done",
      })}
    >
      {statusMessage()}
    </div>
  );
};

export default Status;
