import { FC, useState } from "react";
import useStyles from "./GameCode.style";
import cx from "classnames";
import Button from "../Button/Button";
import { useLocation } from "@reach/router";

const GameCode: FC<{ code: string }> = ({ code }) => {
  const classes = useStyles();
  const location = useLocation();

  const copyLinkHandler = () => {
    navigator.clipboard.writeText(`${location.origin}/?roomCode=${code}`);
  };

  const copyGameCodeHandler = () => {
    navigator.clipboard.writeText(code);
  };

  return (
    <div className={classes.GameCode}>
      <div className={classes.GameCode__code}>Game Code: {code}</div>
      <div className={classes.GameCode__buttonContainer}>
        <Button text="🔗 Copy Game Link" onClick={copyLinkHandler} />
        <Button text="🔗 Copy Game Code" onClick={copyGameCodeHandler} />
      </div>
    </div>
  );
};

export default GameCode;
