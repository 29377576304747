import { Dispatch, MutableRefObject, useEffect, useRef, useState } from "react";
import socketIOClient, { Socket } from "socket.io-client";
import {
  changeSettingAction,
  disconnectSocketAction,
  endGameAction,
  initialiseGameAction,
  initialiseGameSocketAction,
  selectCharacterAction,
  sendWordAction,
  startGameAction,
  changeStatusAction,
  backToLobbyAction,
  incrementWinsAction,
  failedToJoinRoomAction,
} from "../redux/actions";
import { useAppDispatch, useAppSelector } from "./redux";

const SOCKET_SERVER_URL =
  process.env.NODE_ENV === "production" && process.env.REACT_APP_HOST_URL
    ? process.env.REACT_APP_HOST_URL
    : "http://localhost:3080/";

const useConnectGameSocket = (joinRoomCode: string | null, name: string) => {
  const dispatch = useAppDispatch();
  const socketRef = useAppSelector((state) => state.game.socketRef);

  useEffect(() => {
    if (!socketRef || !socketRef.connected) {
      const newSocketRef = socketIOClient(SOCKET_SERVER_URL);

      // on connection to socket - join the room
      newSocketRef.on("connect", async () => {
        newSocketRef?.emit("join", joinRoomCode, name);
      });

      // on joined room - initialise state
      newSocketRef.on("joined", (data) => {
        dispatch(initialiseGameAction(data));
      });

      newSocketRef.on("failedToJoinRoom", () => {
        dispatch(failedToJoinRoomAction());
      });

      newSocketRef.on("changeStatus", (data) => {
        dispatch(changeStatusAction(data));
      });

      newSocketRef.on("startGame", (data) => {
        dispatch(startGameAction(data));
      });

      newSocketRef.on("sendWord", (data) => {
        dispatch(sendWordAction(data));
      });

      newSocketRef.on("endGame", () => {
        dispatch(endGameAction());
      });

      newSocketRef.on("changeSetting", (data) => {
        dispatch(changeSettingAction(data));
      });

      newSocketRef.on("selectCharacter", (data) => {
        dispatch(selectCharacterAction(data));
      });

      newSocketRef.on("backToLobby", (data) => {
        dispatch(backToLobbyAction(data));
      });

      newSocketRef.on("incrementWins", (data) => {
        dispatch(incrementWinsAction(data));
      });

      // on disconnect socket - clear game state and navigate to home
      newSocketRef.on("disconnect", (data) => {
        dispatch(disconnectSocketAction(data));
      });

      // create a new client with our server url
      dispatch(initialiseGameSocketAction(newSocketRef));
    }
  }, []);
};

export default useConnectGameSocket;
