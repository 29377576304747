import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Button: {
    fontSize: "2rem",
    fontFamily: theme.fonts.primary,
    color: theme.colors.primaryDark,
    width: "100%",
    border: "none",
    borderRadius: "1rem",
    cursor: "pointer",
    boxShadow: "1rem 1rem 1rem 0px rgba(0,0,0,0.1)",
    transition: "all 0.15s ease-out",
    transform: "translate(0, -0.2rem)",
    backgroundColor: "#c7ffb3",
    padding: "1rem 2rem",
    "&:hover": {
      boxShadow: "5px 5px 10px 0px rgba(0,0,0,0.15)",
      transform: "translate(0, 0)",
    },
    "&:disabled": {
      backgroundColor: "#bfbfbf",
      boxShadow: "5px 5px 10px 0px rgba(0,0,0,0.15)",
      transform: "translate(0, 0)",
    },
  },
  Button__cancel: {
    backgroundColor: theme.colors.primaryAccent,
  },
}));

export default useStyles;
