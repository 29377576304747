import { makeStyles, ThemeOptions } from "@material-ui/core/styles";

const useStyles = makeStyles<ThemeOptions, { boardSize: number }>((theme) => ({
  GameTile: {},
  GameBoard__tileContainer: {
    display: "flex",
    fontSize: ({ boardSize }) => `${20 - boardSize * 2}rem`,
    position: "relative",
    userSelect: "none",
  },
  GameBoard__tile: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: "50%",
    left: "50%",
    height: "100%",
    width: "100%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#edd953",
    borderRadius: "1rem",
    boxShadow: "0.1rem 0.1rem 0.2rem 0.3rem #ccc",
    transition: "all 0.05s ease-out",
  },
  GameBoard__tile__selected: {
    backgroundColor: "#f4ffb8",
  },
  GameBoard__tile__exists: {
    backgroundColor: "red !important",
  },
  GameBoard__tile__hitBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    height: "100%",
    width: "100%",
    transform: "translate(-50%, -50%)",
    zIndex: 10,
    cursor: "pointer",
  },
  GameBoard__tile__lowHitBox: {
    borderRadius: "30rem",
  },
  GameBoard__newLettersAnimation: {
    animation: `$newLettersEffect 500ms ease-out`,
  },
  "@keyframes newLettersEffect": {
    "0%": {
      opacity: 0,
      backgroundColor: "#edd953",
    },
    "50%": {
      backgroundColor: "#b7ff8a",
    },
    "100%": {
      opacity: 1,
      backgroundColor: "#edd953",
    },
  },
}));

export default useStyles;
