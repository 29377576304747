import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  NumberSelector: {
    display: "flex",
    gap: "2rem",
    alignItems: "center",
  },
  NumberSelector__label: {
    fontSize: "2.4rem",
  },
  NumberSelector__options: {
    display: "flex",
  },
  NumberSelector__number: {
    fontSize: "2.4rem",
    height: "3rem",
    backgroundColor: theme.colors.secondaryAccent2,
    padding: "1rem 2rem",
    textAlign: "center",
    lineHeight: "3.2rem",
    borderRadius: "1rem",
    marginRight: "0.5rem",
    cursor: "pointer",
    userSelect: "none",
  },
  NumberSelector__number__selected: {
    backgroundColor: theme.colors.secondaryBlue1,
  },
}));

export default useStyles;
