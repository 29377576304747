import { FC, useEffect, useState } from "react";
import useStyles from "./GameBoard.style";
import cx from "classnames";
import useSound from "use-sound";
import hoverSound from "../../assets/sounds/hover-sound.mp3";
import correctSound from "../../assets/sounds/correct-sound.mp3";
import wrongSound from "../../assets/sounds/wrong-sound.mp3";
import validateWord from "../../helper/validateWord";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useTransition, animated } from "react-spring";
import {
  resetSelectedTilesAction,
  selectTileAction,
  setTileAnimation,
  subscribeAnimationAction,
} from "../../redux/actions";
import GameTile from "../GameTile/GameTile";
import { nanoid } from "nanoid";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const wordExists = require("word-exists");

const GameBoard: FC<{
  sendWord: (word: string, positions: { row: number; col: number }[]) => void;
  active: boolean;
}> = ({ sendWord, active }) => {
  const boardSize = useAppSelector((state) => state.game.settings.boardSize);
  const classes = useStyles({ boardSize });
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.game.gameBoard);
  const selectedTiles = useAppSelector((state) => state.game.selectedTiles);
  const allowRepeatWords = useAppSelector(
    (state) => state.game.settings.allowRepeatWords
  );
  const minimumWordLength = useAppSelector(
    (state) => state.game.settings.minimumWordLength
  );
  const words = useAppSelector((state) => state.game.words);

  const [isWordDrag, setIsWordDrag] = useState<boolean>(false);
  const [dragPosition, setDragPosition] = useState<
    {
      row: number;
      col: number;
    }[]
  >([]);
  const [playHoverSound] = useSound(hoverSound);
  const [playCorrectSound] = useSound(correctSound);
  const [playWrongSound] = useSound(wrongSound);

  useEffect(() => {
    const startWordDrag = () => {
      setIsWordDrag(true);
    };
    const endWordDrag = () => {
      resetBoardState();
    };

    window.addEventListener("mousedown", startWordDrag);
    window.addEventListener("mouseup", endWordDrag);

    return () => {
      window.removeEventListener("mousedown", startWordDrag);
      window.removeEventListener("mouseup", endWordDrag);
    };
  }, []);

  const selectTileHandler = (rowIndex: number, colIndex: number) => {
    const currentDragPositionIndex = dragPosition.length - 1;

    // New selected tile row is in the acceptable range of values
    if (
      !(
        dragPosition.length === 0 ||
        rowIndex === dragPosition[currentDragPositionIndex]["row"] ||
        rowIndex === dragPosition[currentDragPositionIndex]["row"] + 1 ||
        rowIndex === dragPosition[currentDragPositionIndex]["row"] - 1
      )
    ) {
      resetBoardState();
      return;
    }

    // New selected tile column is in the acceptable range of values
    if (
      !(
        dragPosition.length === 0 ||
        colIndex === dragPosition[currentDragPositionIndex]["col"] ||
        colIndex === dragPosition[currentDragPositionIndex]["col"] + 1 ||
        colIndex === dragPosition[currentDragPositionIndex]["col"] - 1
      )
    ) {
      resetBoardState();
      return;
    }

    // New selected tile has not been selected before
    if (selectedTiles[rowIndex][colIndex]) {
      resetBoardState();
      return;
    }

    dispatch(selectTileAction(rowIndex, colIndex));

    setDragPosition((currentDragPosition) => [
      ...currentDragPosition,
      { row: rowIndex, col: colIndex },
    ]);

    playHoverSound();
  };

  const resetBoardState = () => {
    setIsWordDrag(false);
    dispatch(resetSelectedTilesAction());
    setDragPosition([]);
  };

  const sendWordHandler = () => {
    const word = selectedWord();

    if (word.length < minimumWordLength) {
      playWrongSound();
      dispatch(
        subscribeAnimationAction({
          id: nanoid(),
          key: "short",
        })
      );
      return;
    }

    const wordExistsInList = words.findIndex((data) => data.word === word);

    if (!allowRepeatWords && wordExistsInList !== -1) {
      playWrongSound();
      dispatch(
        subscribeAnimationAction({
          id: nanoid(),
          key: "exists",
          data: {
            index: wordExistsInList,
          },
        })
      );
      return;
    }

    if (wordExists(word)) {
      playCorrectSound();
      sendWord(word, dragPosition);
      return;
    }
  };

  const selectedWord = () => {
    let wordBuilder = "";

    dragPosition.forEach((position) => {
      wordBuilder = `${wordBuilder}${data[position.row][position.col]}`;
    });

    return wordBuilder;
  };

  return (
    <div
      className={cx(classes.GameBoard, {
        [classes.GameBoard__inactive]: !active,
      })}
    >
      <div className={classes.GameBoard__container}>
        {data.map((row, rowIndex) => (
          <>
            {row.map((letter, colIndex) => (
              <GameTile
                rowIndex={rowIndex}
                colIndex={colIndex}
                letter={letter}
                lowHitBox={isWordDrag}
                selectHandler={() => selectTileHandler(rowIndex, colIndex)}
                sendHandler={() => sendWordHandler()}
              />
            ))}
          </>
        ))}
        <div className={classes.GameBoard__display}>{selectedWord()}</div>
      </div>
    </div>
  );
};

export default GameBoard;
