import { FC, useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import useStyles from "./GameTile.style";
import cx from "classnames";
import { useAppSelector } from "../../hooks/redux";
import usePrevious from "../../hooks/usePrevious";
import useSound from "use-sound";

const GameTile: FC<{
  rowIndex: number;
  colIndex: number;
  letter: string;
  lowHitBox: boolean;
  selectHandler: () => void;
  sendHandler: () => void;
}> = ({
  rowIndex,
  colIndex,
  letter,
  lowHitBox,
  selectHandler,
  sendHandler,
}) => {
  const boardSize = useAppSelector((state) => state.game.settings.boardSize);
  const classes = useStyles({ boardSize });
  const prevAmount = usePrevious({ letter });
  const selectedTiles = useAppSelector((state) => state.game.selectedTiles);
  const selected = selectedTiles[rowIndex][colIndex];
  const animations = useAppSelector((state) => state.game.animations);

  const mouseDownHandler = () => {
    selectHandler();
  };

  const mouseUpHandler = () => {
    sendHandler();
  };

  return (
    <div className={classes.GameBoard__tileContainer}>
      <animated.div
        className={cx(classes.GameBoard__tile, {
          [classes.GameBoard__tile__selected]: selected,
          [classes.GameBoard__newLettersAnimation]:
            letter !== prevAmount?.letter,
        })}
      >
        {letter}
      </animated.div>
      <div
        className={cx(classes.GameBoard__tile__hitBox, {
          [classes.GameBoard__tile__lowHitBox]: lowHitBox,
        })}
        onMouseEnter={lowHitBox ? () => selectHandler() : () => {}}
        onMouseDown={mouseDownHandler}
        onMouseUp={mouseUpHandler}
      />
    </div>
  );
};

export default GameTile;
