import { navigate, useLocation } from "@reach/router";
import { FC, useCallback, useEffect } from "react";
import { useAppSelector } from "../../hooks/redux";
import useConnectGameSocket from "../../hooks/useConnectGameSocket";
import useStyles from "./LoadingPage.style";
import CircularProgress from "@material-ui/core/CircularProgress";
import Layout from "../../components/Layout/Layout";
import { WarningOutlined } from "@material-ui/icons";
import Button from "../../components/Button/Button";

interface locationState {
  name: string;
  roomCode: string | null;
}

const LoadingPage: FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const locationState = location.state as locationState | null;
  const userId = useAppSelector((state) => state.game.userId);
  const failedToJoinRoom = useAppSelector(
    (state) => state.game.failedToJoinRoom
  );

  useConnectGameSocket(
    locationState?.roomCode ? locationState?.roomCode : null,
    locationState?.name ? locationState.name : ""
  );

  const backToLobbyHandler = useCallback(() => {
    navigate("/");
  }, [navigate]);

  // After the user has been established - go to lobby
  useEffect(() => {
    if (userId) {
      navigate("/lobby");
    }
  }, [userId]);

  // If the user tries to navigate to this page directly - bump them out
  useEffect(() => {
    if (!locationState?.name) {
      backToLobbyHandler();
    }
  }, [backToLobbyHandler]);

  return (
    <Layout>
      <div className={classes.LoadingPage}>
        {failedToJoinRoom ? (
          <WarningOutlined
            classes={{ root: classes.LoadingPage__warningIcon }}
          />
        ) : (
          <CircularProgress size={"10rem"} />
        )}
        <div>
          {failedToJoinRoom
            ? "Couldn't find the game you were looking for..."
            : "Getting ready for battle!"}
        </div>
        {failedToJoinRoom && (
          <Button
            text="Go back"
            onClick={backToLobbyHandler}
            cancelVariation={true}
          />
        )}
      </div>
    </Layout>
  );
};

export default LoadingPage;
