import { FC } from "react";
import useStyles from "./Input.style";

const Input: FC<{
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
}> = ({ placeholder, value, onChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.Input}>
      <input
        className={classes.Input__input}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default Input;
