import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  CharacterSelection: {
    display: "grid",
    gridTemplateColumns: `repeat(${12}, 1fr)`,
    gap: "1rem",
  },
  CharacterSelection__character: {
    backgroundColor: theme.colors.secondaryBlue3,
    padding: "1rem",
    textAlign: "center",
    borderRadius: "1.5rem",
    fontSize: "5rem",
    cursor: "pointer",
  },
  CharacterSelection__character__selected: {
    backgroundColor: theme.colors.secondaryBlue1,
  },
  CharacterSelection__character__disabled: {
    opacity: 0.3,
    cursor: "not-allowed",
  },
}));

export default useStyles;
