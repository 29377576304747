export const characters = [
  {
    icon: "🐵",
    name: "monkey",
    color: "#ff0000",
  },
  {
    icon: "🐶",
    name: "dog",
    color: "#ff8000",
  },
  {
    icon: "🦁",
    name: "lion",
    color: "#ffff00",
  },
  {
    icon: "🐯",
    name: "tiger",
    color: "#80ff00",
  },
  {
    icon: "🐮",
    name: "cow",
    color: "#00ff00",
  },
  {
    icon: "🐹",
    name: "mouse",
    color: "#00ff80",
  },
  {
    icon: "🐷",
    name: "pig",
    color: "#00ffff",
  },
  {
    icon: "🐰",
    name: "rabbit",
    color: "#0080ff",
  },
  {
    icon: "🐻",
    name: "bear",
    color: "#0000ff",
  },
  {
    icon: "🐻‍❄️",
    name: "polarBear",
    color: "#8000ff",
  },
  {
    icon: "🐨",
    name: "koala",
    color: "#ff00ff",
  },
  {
    icon: "🐼",
    name: "panda",
    color: "#ff0080",
  },
];
