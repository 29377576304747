import { makeStyles, ThemeOptions } from "@material-ui/core/styles";

const useStyles = makeStyles<ThemeOptions, { boardSize: number }>((theme) => ({
  GameBoard: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "0rem 2rem",
  },
  GameBoard__container: {
    position: "relative",
    width: "70vh",
    height: "70vh",
    display: "grid",
    gridTemplateColumns: ({ boardSize }) => `repeat(${boardSize}, 1fr)`,
    gridTemplateRows: ({ boardSize }) => `repeat(${boardSize}, 1fr)`,
    gap: "1.5rem",
  },
  GameBoard__inactive: {
    pointerEvents: "none",
  },
  GameBoard__row: {
    display: "flex",
  },
  GameBoard__display: {
    fontSize: "6rem",
    textAlign: "center",
    position: "absolute",
    top: "0",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#a6ffaf",
    padding: "0rem 2rem",
    borderRadius: "1rem",
  },
}));

export default useStyles;
