import { FC } from "react";
import { useAppSelector } from "../../hooks/redux";
import useGameSocket from "../../hooks/useGameSocket";
import useStyles from "./CharacterSelection.style";
import cx from "classnames";
import { characters } from "../../constants";
import hoverSound from "../../assets/sounds/hover-sound.mp3";
import useSound from "use-sound";
import { Character } from "../../types";

const CharacterSelection: FC = () => {
  const classes = useStyles();
  const players = useAppSelector((state) => state.game.players);
  const userId = useAppSelector((state) => state.game.userId);
  const { selectCharacter } = useGameSocket();
  const [playSound] = useSound(hoverSound);

  const selectedCharacter = players.find(
    (player) => player.userId === userId
  )?.character;

  const shouldDisableCharacter = (character: Character) => {
    // only check other players
    const otherPlayers = players.filter((player) => player.userId !== userId);

    // see if other players have selected this character
    return (
      otherPlayers.filter((player) => player.character?.name === character.name)
        .length > 0
    );
  };

  const selectCharacterHandler = (character: Character) => {
    if (shouldDisableCharacter(character)) return;
    playSound();
    selectCharacter(character);
  };

  return (
    <div className={classes.CharacterSelection}>
      {characters.map((character) => (
        <div
          className={cx(classes.CharacterSelection__character, {
            [classes.CharacterSelection__character__selected]:
              selectedCharacter?.name === character.name,
            [classes.CharacterSelection__character__disabled]:
              shouldDisableCharacter(character),
          })}
          onClick={() => selectCharacterHandler(character)}
        >
          {character.icon}
        </div>
      ))}
    </div>
  );
};

export default CharacterSelection;
