import { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    html: {
      fontSize: "10px",
      fontFamily: "Prompt",
      color: theme.colors.primaryDark,
    },
    body: {
      margin: "0",
      boxSizing: "border-box",
    },
  },
}));

const GlobalStylesProvider: FC = ({ children }) => {
  useStyles();
  return <>{children}</>;
};

export default GlobalStylesProvider;
