import { createReducer } from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";
import { Character, Player } from "../types";
import {
  CHANGE_SETTING,
  CONSUME_ANIMATION,
  DISCONNECT_SOCKET,
  END_GAME,
  INITIALISE_GAME,
  INITIALISE_GAME_SOCKET,
  RESET_SELECTED_TILES,
  SELECT_CHARACTER,
  SELECT_TILE,
  SEND_WORD,
  SET_IS_WORD_VALID,
  START_GAME,
  SUBSCRIBE_ANIMATION,
  CHANGE_STATUS,
  BACK_TO_LOBBY,
  INCREMENT_WINS,
  FAILED_TO_JOIN_ROOM,
} from "./actionTypes";

type GameState = {
  socketRef: Socket | null;
  _id: string;
  failedToJoinRoom: boolean;
  gameBoard: string[][];
  userId: string;
  players: Player[];
  endGamePlayers: Player[];
  words: { userId: string; word: string }[];
  selectedTiles: boolean[][];
  animations: {
    id: string;
    key: string;
    data?: { [key: string]: string | number };
  }[];
  settings: {
    allowRepeatWords: boolean;
    replaceLetters: boolean;
    boardSize: number;
    minimumWordLength: number;
    timer: number;
  };
};

const initialState: GameState = {
  socketRef: null,
  _id: "",
  failedToJoinRoom: false,
  gameBoard: [[]],
  userId: "",
  players: [],
  endGamePlayers: [],
  words: [],
  selectedTiles: [[]],
  animations: [],
  settings: {
    allowRepeatWords: true,
    replaceLetters: true,
    minimumWordLength: 3,
    timer: 60,
    boardSize: 5,
  },
};

type Setting = "allowRepeatWords" | "minimumWordLength";

const gameReducer = createReducer(initialState, {
  [INITIALISE_GAME]: (state, action) => {
    const { _id, players, recentlyJoinedUserId, settings } = action.payload;
    state._id = _id;
    state.settings = settings;

    // If there is no value stored in state - joined user is the current user
    if (!state.userId) {
      state.userId = recentlyJoinedUserId;
    }

    // Do not add players while game is active
    const currentPlayer = state.players.find(
      (player) => player.userId === state.userId
    );

    if (
      currentPlayer?.status === "inProgress" ||
      currentPlayer?.status === "done"
    )
      return;

    state.players = players;
  },
  [CHANGE_STATUS]: (state, action) => {
    const { userId, status } = action.payload;

    state.players = state.players.map((player) => {
      if (player.userId === userId) {
        return {
          ...player,
          status,
        };
      }

      return player;
    });
  },
  [INITIALISE_GAME_SOCKET]: (state, action) => {
    const { socket } = action.payload;

    state.socketRef = socket;
  },
  [DISCONNECT_SOCKET]: (state, action) => {
    state.socketRef = null;
    state._id = initialState._id;
    state.gameBoard = initialState.gameBoard;
    state.userId = initialState.userId;
    state.players = initialState.players;
    state.endGamePlayers = initialState.endGamePlayers;
    state.words = initialState.words;
    state.selectedTiles = initialState.selectedTiles;
    state.animations = initialState.animations;
    state.settings = initialState.settings;
    state.failedToJoinRoom = initialState.failedToJoinRoom;
  },
  [START_GAME]: (state, action) => {
    const { gameBoard } = action.payload;

    state.gameBoard = gameBoard;
    state.players = state.players.map((player) => ({
      ...player,
      status: "inProgress",
      score: 0,
    }));
    state.words = [];
    state.endGamePlayers = [];

    // Client game information
    state.selectedTiles = Array(gameBoard.length).fill(
      Array(gameBoard.length).fill(false)
    );
  },
  [SEND_WORD]: (state, action) => {
    const { userId, word, score, positions, newLetters } = action.payload as {
      userId: string | null;
      word: string;
      score: number;
      positions: { row: number; col: number }[];
      newLetters: string[];
    };

    // Do not send word while waiting in lobby for others
    const currentPlayer = state.players.find(
      (player) => player.userId === state.userId
    );

    if (currentPlayer?.status === "idle" || currentPlayer?.status === "ready")
      return;

    // Add word to word list
    if (userId) {
      state.words.push({ userId, word });
    }

    // Update player score
    state.players = state.players.map((player) => {
      if (player.userId === userId) {
        return { ...player, score: player.score + score };
      }

      return player;
    });

    // Replace the board with new letters
    if (state.settings.replaceLetters) {
      positions.forEach((position, index) => {
        const { row, col } = position;
        state.gameBoard[row][col] = newLetters[index];
      });
    }
  },
  [END_GAME]: (state, action) => {
    state.players = state.players.map((player) => ({
      ...player,
      status: "done",
    }));
    state.endGamePlayers = state.players;
  },
  [SELECT_TILE]: (state, action) => {
    const { row, col } = action.payload;

    state.selectedTiles[row][col] = true;
  },
  [RESET_SELECTED_TILES]: (state, action) => {
    state.selectedTiles = Array(state.gameBoard.length).fill(
      Array(state.gameBoard.length).fill(false)
    );
    // state.isWordValid = { key: "" };
  },
  [SUBSCRIBE_ANIMATION]: (state, action) => {
    const { animation } = action.payload;

    state.animations.push(animation);
  },
  [CONSUME_ANIMATION]: (state, action) => {
    const { id } = action.payload;

    state.animations = state.animations.filter(
      (animation) => animation.id !== id
    );
  },
  [CHANGE_SETTING]: (state, action) => {
    const { settingName, settingValue } = action.payload;
    const typedSettingName = settingName as Setting;

    state.settings = {
      ...state.settings,
      [typedSettingName]: settingValue,
    };
  },
  [SELECT_CHARACTER]: (state, action) => {
    const { userId, character } = action.payload;

    // Do not change character while game is active
    const currentPlayer = state.players.find(
      (player) => player.userId === state.userId
    );

    if (
      currentPlayer?.status === "inProgress" ||
      currentPlayer?.status === "done"
    )
      return;

    const playerIndex = state.players.findIndex(
      (player) => player.userId === userId
    );
    state.players[playerIndex].character = character;
  },
  [BACK_TO_LOBBY]: (state, action) => {
    const { players, settings } = action.payload;

    state.players = players;
    state.settings = settings;
  },
  [INCREMENT_WINS]: (state, action) => {
    const { userId } = action.payload;

    const playerIndex = state.players.findIndex(
      (player) => player.userId === userId
    );
    state.players[playerIndex].wins += 1;
  },
  [FAILED_TO_JOIN_ROOM]: (state, action) => {
    state.failedToJoinRoom = true;
  },
});

export default gameReducer;
