import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { consumeAnimationAction } from "../../redux/actions";
import useStyles from "./Word.style";
import cx from "classnames";
import { characters } from "../../constants";

const Word: FC<{
  word: string;
  index: number;
  userId: string;
}> = ({ word, index, userId }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const animations = useAppSelector((state) => state.game.animations);
  const players = useAppSelector((state) => state.game.players);

  const [animating, setAnimating] = useState<boolean>(false);
  const [activeAnimationId, setActiveAnimationId] = useState<any>(null);

  const shouldAnimate = (index: number) => {
    const foundAnimation = animations
      .filter((animation) => animation.key === "exists")
      .filter((animation) => animation.data?.index === index);

    if (foundAnimation.length > 0) {
      setAnimating(true);
      setActiveAnimationId(foundAnimation[0].id);
    }
  };

  useEffect(() => {
    shouldAnimate(index);
  }, [JSON.stringify(animations)]);

  const onAnimationEnd = () => {
    setAnimating(false);
    dispatch(consumeAnimationAction(activeAnimationId));
  };

  const characterIcon = players.find((player) => player.userId === userId)
    ?.character.icon;

  return (
    <div
      className={cx(classes.Word, { [classes.Word__animation]: animating })}
      onAnimationEnd={() => onAnimationEnd()}
    >
      {word}
      {characterIcon}
    </div>
  );
};

export default Word;
