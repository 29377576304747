import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Settings: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    backgroundColor: theme.colors.secondaryBlue3,
    padding: "1rem 2rem",
    borderRadius: "1.5rem",
  },
  Settings__title: {
    fontSize: "3.2rem",
  },
}));

export default useStyles;
