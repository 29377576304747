import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Checkbox: {
    display: "flex",
    alignItems: "center",
    fontSize: "2.4rem",
  },
  Checkbox__checkbox: {
    "& .MuiSvgIcon-root": {
      fontSize: "4.2rem",
    },
  },
  Checkbox__checkbox__root: {
    color: `${theme.colors.primaryBlue} !important`,
  },
}));

export default useStyles;
