import { FC } from "react";
import { characters } from "../../constants";
import { useAppSelector } from "../../hooks/redux";
import Status from "../Status/Status";
import useStyles from "./Players.style";

const Players: FC = () => {
  const classes = useStyles();
  const players = useAppSelector((state) => state.game.players);

  const renderPlayer = (player: any) => {
    return (
      <div className={classes.Players__player}>
        {player.isHost && (
          <div className={classes.Players__player__host}>👑</div>
        )}
        <div>
          {player.character ? player.character.icon : "❌"}
          {player.name}
          {player.wins ? `🏆x${player.wins}` : ""}
        </div>
        <Status status={player.status} />
      </div>
    );
  };

  return (
    <div className={classes.Players}>
      {players.map((player) => renderPlayer(player))}
    </div>
  );
};

export default Players;
