import { FC } from "react";
import useStyles from "./NumberSelector.style";
import cx from "classnames";

const NumberSelector: FC<{
  value: number;
  label: string;
  onChange: (value: number) => void;
  lowerRange: number;
  increments: number;
  numberOfIncrements: number;
  optionParser?: (value: number) => string;
}> = ({
  value,
  label,
  onChange,
  lowerRange,
  increments,
  numberOfIncrements,
  optionParser,
}) => {
  const classes = useStyles();
  const numberOptions = Array(numberOfIncrements)
    .fill(0)
    .map((_, i) => lowerRange + i * increments);

  const onClickHandler = (newValue: number) => {
    onChange(newValue);
  };

  return (
    <div className={classes.NumberSelector}>
      <div className={classes.NumberSelector__label}>{label}</div>
      <div className={classes.NumberSelector__options}>
        {numberOptions.map((number) => (
          <div
            className={cx(classes.NumberSelector__number, {
              [classes.NumberSelector__number__selected]: value === number,
            })}
            onClick={() => onClickHandler(number)}
          >
            {optionParser ? optionParser(number) : number}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NumberSelector;
