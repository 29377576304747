import { FC } from "react";
import { useAppSelector } from "../../hooks/redux";
import useStyles from "./Leaderboard.style";
import FlipMove from "react-flip-move";

const Leaderboard: FC<{ isEndGame?: boolean }> = ({ isEndGame = false }) => {
  const classes = useStyles();
  const players = useAppSelector((state) => state.game.players);
  const endGamePlayers = useAppSelector((state) => state.game.endGamePlayers);

  const orderedPlayers = [...(isEndGame ? endGamePlayers : players)].sort(
    (a, b) => b.score - a.score
  );

  return (
    <FlipMove className={classes.Leaderboard}>
      {orderedPlayers.map((player) => (
        <div key={player.userId} className={classes.Leaderboard__player}>
          <div className={classes.Leaderboard__name}>
            {player.character.icon} {player.name}
          </div>
          <div className={classes.Leaderboard__score}>{player.score}</div>
        </div>
      ))}
    </FlipMove>
  );
};

export default Leaderboard;
