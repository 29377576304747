import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Status: {
    padding: "0.5rem 1rem",
    borderRadius: "1rem",
    fontSize: "1.5rem",
  },
  Status__isReady: {
    backgroundColor: "#8fff96",
  },
  Status__notReady: {
    backgroundColor: "#ff9696",
  },
}));

export default useStyles;
