import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  LandingPage: {},
  LandingPage__menu: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "2rem",
    width: "45rem",
  },
  LandingPage__joinGameInput: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
  },
  LandingPage__joinGameInput__input: {
    flex: 4,
  },
  LandingPage__joinGameInput__button: {
    flex: 1,
  },
}));

export default useStyles;
