import { FC, useEffect } from "react";
import TitleBanner from "../../components/TitleBanner/TitleBanner";
import useStyles from "./LobbyPage.style";
import Button from "../../components/Button/Button";
import { useLocation, useNavigate } from "@reach/router";
import useGameSocket from "../../hooks/useGameSocket";
import useConnectGameSocket from "../../hooks/useConnectGameSocket";
import buttonCancelSound from "../../assets/sounds/button-cancel-sound.mp3";
import { useAppSelector } from "../../hooks/redux";
import GameCode from "../../components/GameCode/GameCode";
import Layout from "../../components/Layout/Layout";
import Settings from "../../components/Settings/Settings";
import Players from "../../components/Players/Players";
import CharacterSelection from "../../components/CharacterSelection/CharacterSelection";
import { useCleanup } from "../../hooks/useCleanup";

interface locationState {
  name: string;
  roomCode: string | null;
}

const LobbyPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const roomCode = useAppSelector((state) => state.game._id);
  const boardSize = useAppSelector((state) => state.game.settings.boardSize);
  const players = useAppSelector((state) => state.game.players);
  const userId = useAppSelector((state) => state.game.userId);
  const locationState = location.state as locationState | null;

  const classes = useStyles();

  useEffect(() => {
    const allPlayersInProgress =
      players.length > 0 &&
      players.filter((player) => player.status === "inProgress").length ===
        players.length;

    if (allPlayersInProgress) {
      navigate("/battle");
    }
  }, [players]);

  useCleanup();

  useConnectGameSocket(
    locationState?.roomCode ? locationState?.roomCode : null,
    locationState?.name ? locationState.name : ""
  );

  const { changeStatus, startGame, disconnectGame } = useGameSocket();

  const startHandler = () => {
    startGame(boardSize);
  };

  const cancelHandler = () => {
    disconnectGame();
    navigate("/");
  };

  const readyHandler = () => {
    const playerStatus = players.find(
      (player) => player.userId === userId
    )?.status;
    const newStatus = playerStatus === "ready" ? "idle" : "ready";
    changeStatus(newStatus);
  };

  const readyText = () => {
    const playerStatus = players.find(
      (player) => player.userId === userId
    )?.status;

    if (playerStatus === "idle") return "Ready Up";
    return "Unready";
  };

  const readyToStart =
    // all players are ready
    players.filter((player) => player.status === "ready").length ===
      players.length &&
    // all players has selected a character
    players.filter((player) => Boolean(player.character)).length ===
      players.length;

  const isHost = players.find((player) => player.userId === userId)?.isHost;

  return (
    <Layout>
      <div className={classes.LobbyPage}>
        <TitleBanner />
        <div className={classes.LobbyPage__container}>
          <GameCode code={roomCode} />
          <CharacterSelection />
          <div className={classes.LobbyPage__buttons}>
            <Button text={readyText()} onClick={readyHandler} />
            <Button
              text="Start Game"
              onClick={startHandler}
              disabled={!readyToStart || !isHost}
            />
            <Button
              text="Leave Lobby"
              onClick={cancelHandler}
              sound={buttonCancelSound}
              cancelVariation={true}
            />
          </div>

          <div className={classes.LobbyPage__main}>
            <Players />
            <Settings />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LobbyPage;
