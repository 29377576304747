import { Socket } from "socket.io-client";
import {
  CHANGE_SETTING,
  CONSUME_ANIMATION,
  DISCONNECT_SOCKET,
  END_GAME,
  INITIALISE_GAME,
  INITIALISE_GAME_SOCKET,
  RESET_SELECTED_TILES,
  SELECT_CHARACTER,
  SELECT_TILE,
  SEND_WORD,
  SET_IS_WORD_VALID,
  START_GAME,
  SUBSCRIBE_ANIMATION,
  CHANGE_STATUS,
  BACK_TO_LOBBY,
  INCREMENT_WINS,
  FAILED_TO_JOIN_ROOM,
} from "./actionTypes";

export const initialiseGameAction = (data: any) => ({
  type: INITIALISE_GAME,
  payload: data,
});

export const disconnectSocketAction = (data: any) => ({
  type: DISCONNECT_SOCKET,
  payload: data,
});

export const changeStatusAction = (data: any) => ({
  type: CHANGE_STATUS,
  payload: data,
});

export const initialiseGameSocketAction = (socket: Socket) => ({
  type: INITIALISE_GAME_SOCKET,
  payload: { socket },
});

export const startGameAction = (data: any) => ({
  type: START_GAME,
  payload: data,
});

export const sendWordAction = (data: any) => ({
  type: SEND_WORD,
  payload: data,
});

export const endGameAction = () => ({
  type: END_GAME,
  payload: {},
});

export const selectTileAction = (row: number, col: number) => ({
  type: SELECT_TILE,
  payload: { row, col },
});

export const resetSelectedTilesAction = () => ({
  type: RESET_SELECTED_TILES,
  payload: {},
});

export const setTileAnimation = (isWordValid: {
  key: string;
  dragPosition?: {
    row: number;
    col: number;
  }[];
  player?: string;
  index?: number;
}) => ({
  type: SET_IS_WORD_VALID,
  payload: { isWordValid },
});

export const subscribeAnimationAction = (animation: {
  id: string;
  key: string;
  data?: { [key: string]: string | number };
}) => ({
  type: SUBSCRIBE_ANIMATION,
  payload: { animation },
});

export const consumeAnimationAction = (id?: string) => ({
  type: CONSUME_ANIMATION,
  payload: { id },
});

export const changeSettingAction = (data: any) => ({
  type: CHANGE_SETTING,
  payload: data,
});

export const selectCharacterAction = (data: any) => ({
  type: SELECT_CHARACTER,
  payload: data,
});

export const backToLobbyAction = (data: any) => ({
  type: BACK_TO_LOBBY,
  payload: data,
});

export const incrementWinsAction = (data: any) => ({
  type: INCREMENT_WINS,
  payload: data,
});

export const failedToJoinRoomAction = () => ({
  type: FAILED_TO_JOIN_ROOM,
  payload: {},
});
