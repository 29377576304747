import React, {
  Dispatch,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import useStyles from "./BattlePage.style";
import io, { Socket } from "socket.io-client";
import { navigate, useLocation } from "@reach/router";
import GameBoard from "../../components/GameBoard/GameBoard";
import WordList from "../../components/WordList/WordList";
import TitleBanner from "../../components/TitleBanner/TitleBanner";
import Timer from "../../components/Timer/Timer";
import useGameSocket from "../../hooks/useGameSocket";
import { useAppSelector } from "../../hooks/redux";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";
import Leaderboard from "../../components/Leaderboard/Leaderboard";
import { useCleanup } from "../../hooks/useCleanup";

const BattlePage: React.FC = () => {
  const classes = useStyles();
  const players = useAppSelector((state) => state.game.players);
  const words = useAppSelector((state) => state.game.words);
  const userId = useAppSelector((state) => state.game.userId);

  useCleanup();

  const { sendWord, endGame, backToLobby, incrementWins } = useGameSocket();

  // When the game state has been restarted go back to lobby
  // useEffect(() => {
  //   const idlePlayerDetected =
  //     players.filter((player) => player.status === "idle").length ===
  //     players.length;
  //   if (idlePlayerDetected) {
  //     fetchLobby();
  //     navigate("/lobby");
  //   }
  // }, [players]);

  useEffect(() => {
    // No players - go back to landing page
    if (players.length === 0) {
      //TODO: put error modal here.
      navigate("/");
    }
  }, [players]);

  const playerStatus = players.find(
    (player) => player.userId === userId
  )?.status;

  useEffect(() => {
    const allPlayersDone =
      players.filter((player) => player.status === "done").length ===
      players.length;

    if (allPlayersDone) {
      navigate("/endgame");
    }
  }, [players]);

  return (
    <div className={classes.BattlePage}>
      <TitleBanner />
      <div className={classes.BattlePage__timerContainer}>
        {playerStatus === "inProgress" && <Timer onTimerEnd={endGame} />}
      </div>

      <div className={classes.BattlePage__layout}>
        <div className={classes.BattlePage__layout__wordList}>
          <Leaderboard />
        </div>
        <div className={classes.BattlePage__layout__gameBoard}>
          <GameBoard
            sendWord={sendWord}
            active={playerStatus === "inProgress"}
          />
        </div>
        <div className={classes.BattlePage__layout__wordList}>
          <WordList words={words} position="left" />
        </div>
      </div>
      {/* <Modal isOpen={allPlayersDone} onClose={modalOnCloseHandler}>
        <div className={classes.BattlePage__winner}>
          <div>Final Scores!</div>
          <Leaderboard />
          <Button text="Back to lobby" onClick={restartGameHandler} />
        </div>
      </Modal> */}
    </div>
  );
};

export default BattlePage;
