import { makeStyles } from "@material-ui/core/styles";
import AlphabetBackground from "../../assets/alphabet-background.jpeg";

const useStyles = makeStyles((theme) => ({
  Layout: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    gap: "5rem",
    "&::after": {
      content: "''",
      height: "100vh",
      width: "100vw",
      backgroundImage: `url(${AlphabetBackground})`,
      backgroundRepeat: "repeat",
      backgroundSize: "50rem",
      position: "absolute",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
      opacity: 0.15,
      zIndex: "-1000",
    },
  },
  Layout__ad: {
    minWidth: "20rem",
  },
}));

export default useStyles;
