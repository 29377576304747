import { navigate } from "@reach/router";
import { FC, useEffect, useRef } from "react";
import Button from "../../components/Button/Button";
import Leaderboard from "../../components/Leaderboard/Leaderboard";
import { useAppSelector } from "../../hooks/redux";
import { useCleanup } from "../../hooks/useCleanup";
import useGameSocket from "../../hooks/useGameSocket";
import useStyles from "./EndGamePage.style";

const EndGamePage: FC = () => {
  const classes = useStyles();
  const players = useAppSelector((state) => state.game.players);
  const userId = useAppSelector((state) => state.game.userId);

  useCleanup();

  const { backToLobby, incrementWins } = useGameSocket();

  useEffect(() => {
    // if the current player is the winner - increment the score
    let highestScore = -1;
    players.forEach((player) => {
      if (player.score > highestScore) {
        highestScore = player.score;
      }
    });

    const currentPlayer = players.find((player) => player.userId === userId);

    if (currentPlayer?.score === highestScore) {
      incrementWins();
    }
  }, []);

  const restartGameHandler = () => {
    backToLobby();
    navigate("/lobby");
  };

  return (
    <div className={classes.EndGamePage}>
      <div className={classes.EndGamePage__container}>
        <div>Final Scores!</div>
        <Leaderboard isEndGame />
        <Button text="Back to lobby" onClick={restartGameHandler} />
      </div>
    </div>
  );
};

export default EndGamePage;
