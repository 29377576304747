import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Players: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  Players__player: {
    position: "relative",
    padding: "0.5rem",
    fontSize: "3rem",
    backgroundColor: theme.colors.secondaryBlue2,
    borderRadius: "1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "45rem",
    transition: "all 0.15s ease-out",
  },
  Players__player__host: {
    position: "absolute",
    left: "0",
    top: "50%",
    transform: "translate(-100%, -65%)",
  },
}));

export default useStyles;
