import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Leaderboard: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  Leaderboard__player: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.colors.secondaryBlue1,
    borderRadius: "1.5rem",
    fontSize: "3rem",
    justifyContent: "space-between",
    padding: "1rem 2rem",
  },
  Leaderboard__name: {},
  Leaderboard__score: {
    textAlign: "right",
  },
}));

export default useStyles;
