import { Router } from "@reach/router";
import { FC, useEffect, useRef, useState } from "react";
import { Socket } from "socket.io-client";
import BattlePage from "./pages/BattlePage/BattlePage";
import EndGamePage from "./pages/EndGamePage/EndGamePage";
import LandingPage from "./pages/LandingPage/LandingPage";
import LoadingPage from "./pages/LoadingPage/LoadingPage";
import LobbyPage from "./pages/LobbyPage/LobbyPage";
import Route from "./route";

const App: FC = () => (
  <Router>
    <Route path="/" component={LandingPage} />
    <Route path="/loading" component={LoadingPage} />
    <Route path="/lobby" component={LobbyPage} />
    <Route path="/battle" component={BattlePage} />
    <Route path="/endgame" component={EndGamePage} />
  </Router>
);

export default App;
