/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect } from "react";
import useStyles from "./Layout.style";
import { Adsense } from "@ctrl/react-adsense";
import cx from "classnames";

const Layout: FC = ({ children }) => {
  const classes = useStyles();

  // useEffect(() => {
  //   // @ts-ignore
  //   if (window) (window.adsbygoogle = window.adsbygoogle || []).push({});
  // }, []);

  return (
    <div className={classes.Layout}>
      <div className={classes.Layout__ad}>
        <Adsense
          style={{ display: "block", border: "1px solid red" }}
          client="ca-pub-7068157398089180"
          slot="6837460493"
          format="auto"
          responsive="false"
          adTest="on"
        />
      </div>
      {children}
      <div className={classes.Layout__ad}>
        <Adsense
          style={{ display: "block", border: "1px solid red" }}
          client="ca-pub-7068157398089180"
          slot="6837460493"
          format="auto"
          responsive="false"
          adTest="on"
        />
      </div>
    </div>
  );
};

export default Layout;
