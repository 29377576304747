import { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/redux";
import useStyles from "./Timer.style";

const Timer: FC<{ onTimerEnd: () => void }> = ({ onTimerEnd }) => {
  const classes = useStyles();
  const timer = useAppSelector((state) => state.game.settings.timer);
  const [timeRemaining, setTimeRemaining] = useState<number>(timer);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining((currentTimeRemaining) => {
        if (currentTimeRemaining === 0) return 0;
        return currentTimeRemaining - 1;
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (timeRemaining === 0) {
      onTimerEnd();
    }
  }, [timeRemaining]);

  return <div className={classes.Timer}>{timeRemaining}</div>;
};

export default Timer;
