import { FC } from "react";
import useStyles from "./Settings.style";
import Checkbox from "../Checkbox/Checkbox";
import { useAppSelector } from "../../hooks/redux";
import useGameSocket from "../../hooks/useGameSocket";
import NumberSelector from "../NumberSelector/NumberSelector";
import correctSound from "../../assets/sounds/hover-sound.mp3";
import useSound from "use-sound";

const Settings: FC = () => {
  const classes = useStyles();
  const { changeSetting } = useGameSocket();

  const allowRepeatWords = useAppSelector(
    (state) => state.game.settings.allowRepeatWords
  );
  const replaceLetters = useAppSelector(
    (state) => state.game.settings.replaceLetters
  );
  const boardSize = useAppSelector((state) => state.game.settings.boardSize);
  const minimumWordLength = useAppSelector(
    (state) => state.game.settings.minimumWordLength
  );
  const timer = useAppSelector((state) => state.game.settings.timer);
  const players = useAppSelector((state) => state.game.players);
  const userId = useAppSelector((state) => state.game.userId);

  const [playSound] = useSound(correctSound);

  const isHost = players.find((player) => player.userId === userId)?.isHost;

  const onChangeHandler = (value: boolean) => {
    playSound();
    isHost && changeSetting("allowRepeatWords", value);
  };

  const onReplaceLettersChangeHandler = (value: boolean) => {
    playSound();
    isHost && changeSetting("replaceLetters", value);
  };

  const onBoardSizeChangeHandler = (value: number) => {
    playSound();
    isHost && changeSetting("boardSize", value);
  };

  const onMinimumWordLengthChangeHandler = (value: number) => {
    playSound();
    isHost && changeSetting("minimumWordLength", value);
  };

  const onTimerChangeHandler = (value: number) => {
    playSound();
    isHost && changeSetting("timer", value);
  };

  return (
    <div className={classes.Settings}>
      <Checkbox
        value={allowRepeatWords}
        label="Can repeat words"
        onChange={onChangeHandler}
      />
      <Checkbox
        value={replaceLetters}
        label="Replace letters"
        onChange={onReplaceLettersChangeHandler}
      />
      <NumberSelector
        value={boardSize}
        label="Board Size"
        onChange={onBoardSizeChangeHandler}
        lowerRange={4}
        increments={1}
        numberOfIncrements={5}
        optionParser={(value) => `${value}x${value}`}
      />
      <NumberSelector
        value={minimumWordLength}
        label="Minimum word length"
        onChange={onMinimumWordLengthChangeHandler}
        lowerRange={3}
        increments={1}
        numberOfIncrements={5}
      />
      <NumberSelector
        value={timer}
        label="Timer (seconds)"
        onChange={onTimerChangeHandler}
        lowerRange={30}
        increments={30}
        numberOfIncrements={5}
      />
    </div>
  );
};

export default Settings;
