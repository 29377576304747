/* eslint-disable @typescript-eslint/no-empty-interface */
import React from "react";
import { ThemeProvider as MUIThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles" {
  interface Theme {
    colors: {
      primaryBlue: string;
      primaryDark: string;
      primaryGreen: string;
      primaryAccent: string;
      secondaryBlue1: string;
      secondaryBlue2: string;
      secondaryBlue3: string;
      secondaryDark1: string;
      secondaryDark2: string;
      secondaryDark3: string;
      secondaryGreen1: string;
      secondaryGreen2: string;
      secondaryAccent1: string;
      secondaryAccent2: string;
      white: string;
    };
    fonts: {
      primary: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    colors: {
      primaryBlue: string;
      primaryDark: string;
      primaryGreen: string;
      primaryAccent: string;
      secondaryBlue1: string;
      secondaryBlue2: string;
      secondaryBlue3: string;
      secondaryDark1: string;
      secondaryDark2: string;
      secondaryDark3: string;
      secondaryGreen1: string;
      secondaryGreen2: string;
      secondaryAccent1: string;
      secondaryAccent2: string;
      white: string;
    };
    fonts: {
      primary: string;
    };
  }
}

const theme = createTheme({
  colors: {
    // Primary Colors
    primaryBlue: "#3994F5",
    primaryDark: "#2d445C",
    primaryGreen: "#2ed47a",
    primaryAccent: "#ffc107",

    // Secondary Blues
    secondaryBlue1: "#a6cffa",
    secondaryBlue2: "#e2effe",
    secondaryBlue3: "#f7fafe",

    // Secondary Darks
    secondaryDark1: "#96a2ae",
    secondaryDark2: "#cad0d6",
    secondaryDark3: "#e4e4e4",

    // Secondary Greens
    secondaryGreen1: "#009406",
    secondaryGreen2: "#006a78",

    // Secondary Accents
    secondaryAccent1: "#f53966",
    secondaryAccent2: "#def9f9",

    // Generic Colors
    white: "#FFFFFF",
  },
  fonts: {
    primary: "Prompt",
  },
});

const ThemeProvider: React.FC = ({ children }) => {
  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
};

export default ThemeProvider;
