import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  LoadingPage: {
    height: "90vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "2rem",
    fontSize: "3rem",
  },
  LoadingPage__warningIcon: {
    fontSize: "15rem",
  },
}));

export default useStyles;
