import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  GameCode: {
    display: "flex",
    fontSize: "4rem",
    gap: "2rem",
    justifyContent: "center",
    alignContent: "center",
  },
  GameCode__code: {
    display: "flex",
    alignItems: "center",
    border: "0.5rem solid #8383D9",
    borderRadius: "1rem",
    padding: "0 1rem",
    position: "relative",
    width: "100%",
    justifyContent: "center",
  },
  GameCode__copyToClipboard: {
    position: "absolute",
    right: "0",
    top: "0",
    fontSize: "2rem",
    lineHeight: "2rem",
    transform: "translate(0, -3rem)",
  },
  GameCode__copyToClipboard__copied: {
    color: "#27b04b",
  },
  GameCode__buttonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    width: "100%",
  },
}));

export default useStyles;
