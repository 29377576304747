import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Timer: {
    padding: "1rem 2rem",
    fontSize: "5rem",
  },
}));

export default useStyles;
