import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  BattlePage: {},
  BattlePage__timerContainer: {
    display: "flex",
    height: "10rem",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  BattlePage__layout: {
    display: "flex",
    padding: "0 5rem",
  },
  BattlePage__layout__wordList: {
    flex: 10,
  },
  BattlePage__layout__gameBoard: {
    flex: 1,
    display: "flex",
  },
}));

export default useStyles;
