import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Input: {
    border: "0.5rem solid #8383D9",
    borderRadius: "1rem",
    width: "calc(100% - 1rem)",
    height: "calc(7rem - 1rem)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  Input__input: {
    width: "100%",
    fontSize: "3rem",
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    textAlign: "center",
    padding: "1rem 0",
    textTransform: "uppercase",
    fontFamily: theme.fonts.primary,
  },
}));

export default useStyles;
